import * as auth from '../utils/auth';

const initialState = {
	isLoggedIn: auth.getToken() ? true : false,
	token: auth.getToken() !== null ? `Bearer ${auth.getToken()}` : null,
	user_token: auth.getToken() !== null ? auth.getToken() : null,
	user: !auth.getUser() ? {} : auth.getUser(),
	notification: {},
	isLoggedInSuccess: 0,
	status_response: 200,
	refresh_pp: false,
	show_notif_login: false,
};

export default (state = initialState, action) => {
	switch(action.type) {
		case 'LOGIN_SUCCESS':
			return {
				...state,
				isLoggedIn: true,
				isLoggedInSuccess: 1,
				status_response: 200
			};
		case 'LOGIN_FAILURE':
			return {
				...state,
				isLoggedIn: false,
				isLoggedInSuccess: 2,
				notification: action.error,
				show_notif_login: action.show,
				status_response: action.error.status
			};

		case 'TOKEN_INVALID':
			return {
				status_response: action.status_response,
				notification: action.notification
			};
		case 'REFRESH_PROFILE_PICTURE':
			return {
				...state,
				refresh_pp: action.change_pp
			}
		default:
			return state
	}
}