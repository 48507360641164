const rightSideReducer = (state = {}, action) => {
    switch (action.type) {
      case 'RIGHT_SIDE_ITEM_ORDER':
            return {
                trigger: action.type,
                show: action.show,
                data: action.data
            }
      case 'RIGHT_SIDE_COVER':
            return {
                trigger: action.type,
                show: action.show,
            }
      case 'CLOSE_OPEN_RIGHT_SIDE':
          return {
              type: action.type,
              show: action.show
          }

      case 'REPLY_COMMENT_ACTIVITY_PAGE':
          return {
              type: action.type,
              show: action.show,
              data: action.data
          }

      case 'UPDATE_COUNT_REPLY_CLIENT_COMMENT':
          return {
              type: action.type,
              show: action.show,
              data: action.data
          }

      default:
            return state
    }
}

export default rightSideReducer;
