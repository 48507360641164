const kanbanReducer = (state = {}, action) => {
    switch (action.type) {
      case 'REFRESH_CHANELL_FROM_KANBAN':
            return {
                trigger: action.type,
                show: action.show,
            }
      default:
            return state
    }
}

export default kanbanReducer;
