var secretKey = 'm3r4k1M0tionsLog2021#';

var author = 'Edit My Story';
var author_text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt utlabore et dolore magna aliqua.';

var main_domain = 'https://test.editmystory.com/'; //live 
// var main_domain = "http://127.0.0.1:8000/"; //test
var api_url = main_domain+"api/"; 
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	main_domain = "http://127.0.0.1:8000/";
	api_url = main_domain+"api/"; 
}

var redirect_url = {
	user_before_login: '/login',
	user_reset_password: '/reset/token/find/:token?',
	user_logout: '/logout',
	user_after_login: '/dashboard'
}

var config_name = {
	session_storage: 'setDataBackendSessionMM15',
	token_storage: 'setDataBackendTokenMMl15',
	user_type_storage: 'setDataBackendUserTypeMMl15',
	user_meta: 'setBackendUserMetaMMl15',
	search_company: 'setBackendSearchCompanyMMl15',
	search_order_code: 'setBackendSearchOrderCodeMMl15',
	search_project: 'setBackendSearchProjectMMl15',
	activity_content: 'setBackendActivityContentMMl15'
};

module.exports = {
	secretKey: secretKey,
	author: author,
	author_text: author_text,
	main_domain: main_domain,
	api_url: api_url,
	redirect_url: redirect_url,
	config_name: config_name,
	
};
