const urlReducer = (state = {}, action) => {
  	switch (action.type) {
	    case 'CHANGE_URL':
	      	return {
      			action: action.trigger,
	      		url: action.url,
	      	}
	    default:
      		return state
  	}
}

export default urlReducer;