import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';

import asyncComponent from './AsyncComponent'
import Default from './components/Default';
import * as auth from './components/utils/auth';
import { store, history } from './components/helpers';
import { icons } from './components/assets/icons'

import "react-datepicker/dist/react-datepicker.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-splitter-layout/lib/index.css';
import "react-image-crop/dist/ReactCrop.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import './components/scss/style.scss';
	
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	console.log('dev code')
} else {
	console.log('production code')
}

const Login_user = asyncComponent(() =>
    import('./components/page_site/auth/Login_user').then(module => module.default)
)
const Logout_user = asyncComponent(() =>
    import('./components/page_site/auth/Logout_user').then(module => module.default)
)
const Reset_password = asyncComponent(() =>
    import('./components/page_site/auth/Reset_password').then(module => module.default)
)
const OnePage = asyncComponent(() =>
	import('./components/page_site/admin_page/layout/OnePage').then(module => module.default)
)

const PrivateRouteUser = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props =>
		auth.isAllowed('guest') ?
			(<Component {...props} />)
			:
			(<Redirect to={{ pathname: Default.redirect_url.user_before_login, state: { from: props.location } }} />)
	} />
)

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//       .register("./firebase-messaging-sw.js")
//       .then(function(registration) {
//         	// console.log("Registration successful, scope is:", registration.scope);
//       })
//       .catch(function(err) {
//         	// console.log("Service worker registration failed, error:", err);
//       });
// }

React.icons = icons

ReactDOM.render(
	<Provider store={store}>
		<div className="App">
			<BrowserRouter history={history}>

				{/*Login/Logout/Forgot*/}
				<Route exact path="/" component={Login_user} />
				<Route path={Default.redirect_url.user_before_login} component={Login_user} />
				<Route path={Default.redirect_url.user_logout} component={Logout_user} />
				<Route path={Default.redirect_url.user_reset_password} component={Reset_password} />

				{/*admin page*/}
				<PrivateRouteUser path="/dashboard" component={OnePage} />
				<PrivateRouteUser path="/company" component={OnePage} />
				<PrivateRouteUser path="/order-list" component={OnePage} />
				<PrivateRouteUser path="/manage-user" component={OnePage} />
				<PrivateRouteUser path="/manage-price" component={OnePage} />
				<PrivateRouteUser path="/manage-currency" component={OnePage} />
				<PrivateRouteUser path="/manage-coupon" component={OnePage} />
				<PrivateRouteUser path="/manage-faq" component={OnePage} />
				<PrivateRouteUser path="/manage-blog" component={OnePage} />
				<PrivateRouteUser path="/manage-static-page" component={OnePage} />
				<PrivateRouteUser path="/contact-form" component={OnePage} />
				<PrivateRouteUser path="/report-issue" component={OnePage} />
				<PrivateRouteUser path="/notification" component={OnePage} />
				<PrivateRouteUser path="/profile" component={OnePage} />
			</BrowserRouter>
		</div>
	</Provider>
	,
	document.getElementById('root')
);