const notificationReducer = (state = {}, action) => {
	// console.log("action.quer", action);
  	switch (action.type) {
	    case 'UPDATE_NOTIFICATION':
	      	return {
	      		type: action.type,
	      		show: action.show,
	      		position: action.position,
      			timeout: action.timeout,
	      		notification: action.notification
	      	}	
      	case 'REAL_TIME_NOTIFICATION':
	      	return {
	      		type: action.type,
	      		data: action.data
			  }
		case 'DELETE_DATA_NOTIFICATION':
			return {
				type: action.type,
				data: action.data,
				from: action.from,
			}
		case 'UPDATE_DATA_NOTIFICATION':
			return {
				type: action.type,
				data: action.data,
				from: action.from,
			}
		case 'COUNT_DATA_NOTIFICATION_MENTION':
			return {
				type: action.type,
				show: action.show,
			}
	    default:
      		return state
  	}
}

export default notificationReducer;