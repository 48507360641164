const formReducer = (state = {}, action) => {
  	switch (action.type) {
	    case 'UPDATE_FORM_DATA':
	      	return {
	      		type: action.type,
	      		form: action.form,
	      		trigger: action.trigger,
	      		action: action.action, //update / insert / delete
				category_data: action.category_data, // category data : list or other
				index_data: action.index_data,
				data: action.data
	      	}
      	case 'UPDATE_USER_DATA':
	      	return {
	      		type: action.type,
	      		action: action.action,
				data: action.data
	      	}
      	case 'DELETE_USER_DATA':
	      	return {
	      		type: action.type,
	      		action: action.action,
				data: action.data
	      	}
	    default:
      		return state
  	}
}

export default formReducer;
