import { combineReducers } from 'redux';

import authReducer  from './authReducer';
import formReducer from './formReducer';
import modalReducer  from './modalReducer';
import notificationReducer from './notificationReducer';
import pageReducer from './pageReducer'
import rightPanelReducer  from './rightPanelReducer';
import searchReducer from './searchReducer'
import urlReducer from './urlReducer';
import kanbanReducer from './kanbanReducer'

const todoApp = combineReducers({
    authReducer,
    formReducer,
    modalReducer,
    notificationReducer,
    pageReducer,
    rightPanelReducer,
    searchReducer,
    urlReducer,
    kanbanReducer
})

export default todoApp
