const searchReducer = (state = {}, action) => {
    switch (action.type) {
      case 'SHOW_SEARCH':
            return {
                trigger: action.trigger,
                show: action.show,
                keyword: action.keyword,
                action: action.action,
            }
      default:
            return state
    }
}

export default searchReducer;