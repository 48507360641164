import * as auth from './auth';
import Default from '../Default';
var axios = require('axios');
var Promise = require('promise');

const authHeaders = () => {
	return {
		Authorization: auth.getToken() !== null ? `Bearer ${auth.getToken()}` :null,
	};
}

export const headerToken= () => {
	return authHeaders();
}

export const get = (endpoint) => {
	return axios({
		method: 'get',
		url: Default.api_url + endpoint,
		headers: authHeaders(),
	});
}

export const post = (endpoint, data) => {
	return new Promise((resolve, reject) => {
		return axios({
			method: 'post',
			url: Default.api_url + endpoint,
			data: data,
			headers: authHeaders(),
		})
		.then((response) => {
			// console.log(response);
			resolve(response);
		})
		.catch((error) => {
			reject(error); // console.log(error);
		});
	});
}	

export const deleteData = (endpoint, data) => {
	return new Promise((resolve, reject) => {
		return axios({
			method: 'delete',
			url: Default.api_url + endpoint,
			data: data,
			headers: authHeaders(),
		})
		.then((response) => {
			// console.log(response);
			resolve(response);
		})
		.catch((error) => {
			reject(error); // console.log(error);
		});
	});
}

export const updateData = (endpoint, data) => {
	return new Promise((resolve,reject) => {
		return axios({
			method: 'put',
			url: Default.api_url + endpoint,
			data: data,
			headers: authHeaders(),
		})
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		})
	})
}

export const updateNotificationStatus = (id, status) => {
    return new Promise((resolve, reject) => {
    	var data_id = [];
        data_id.push([id, '', status]);
		return axios({
			method: 'post',
			url: Default.api_url + 'user/notification/update/status',
			data: {id: data_id},
			headers: authHeaders(),
		})
		.then((response) => {
			// console.log(response);
			resolve(response);
		})
		.catch((error) => {
			reject(error); // console.log(error);
		});
	});
}