import * as api from './api';
import Default from '../Default';

var Promise = require('promise');

export const encryptData = (plainText) => {
	if(plainText !== null){
		var ciphertext = window.btoa(plainText)
		return ciphertext;
	}
	return null;
}
export const decryptData = (ciphertext) => {
	if(ciphertext !== null){
		var originalText = window.atob(ciphertext)
		return originalText;
	}
	return null;
}

export const setToken = (token) => { localStorage.setItem(Default.config_name.token_storage, encryptData(token)); }
export const getToken = () => decryptData(localStorage.getItem(Default.config_name.token_storage));

export const setFcmToken = (token) => { localStorage.setItem(Default.config_name.token_storage+'_fcm', encryptData(token)); }
export const getFcmToken = () => decryptData(localStorage.getItem(Default.config_name.token_storage+'_fcm'));

export const setUser = (user) => { localStorage.setItem(Default.config_name.session_storage, encryptData(JSON.stringify(user))); }
export const getUser = () => {
	const user = localStorage.getItem(Default.config_name.session_storage);
	return JSON.parse(decryptData(user));
}

export const setUserType = (type) => { localStorage.setItem(Default.config_name.user_type_storage, encryptData(type)); }
export const getUserType = () => decryptData(localStorage.getItem(Default.config_name.user_type_storage));

export const setUserMeta = (meta) => { localStorage.setItem(Default.config_name.user_meta, encryptData(JSON.stringify(meta))); }
export const getUserMeta = () => decryptData(localStorage.getItem(Default.config_name.user_meta));

export const setSearchCompany = (data) => { localStorage.setItem(Default.config_name.search_company, encryptData(JSON.stringify(data)));}
export const getSearchCompany = () => JSON.parse(decryptData(localStorage.getItem(Default.config_name.search_company)));

export const setSearchOrderCode = (data) => { localStorage.setItem(Default.config_name.search_order_code, encryptData(JSON.stringify(data)));}
export const getSearchOrderCode = () => JSON.parse(decryptData(localStorage.getItem(Default.config_name.search_order_code)));

export const setSearchProject = (data) => { localStorage.setItem(Default.config_name.search_project, encryptData(JSON.stringify(data)));}
export const getSearchProject = () => JSON.parse(decryptData(localStorage.getItem(Default.config_name.search_project)));

export const setActivityContent = (data) => { localStorage.setItem(Default.config_name.activity_content, encryptData(JSON.stringify(data)));}
export const getActivityContent = () => JSON.parse(decryptData(localStorage.getItem(Default.config_name.activity_content)));

export const isLoggedIn = () => {
	return getToken();
}

export const isCategoryUser = () => {
	return getUserType();
}

export const isAllowed = (type) => {
	const getType = getUserType();

	if(type !== null){
		if(getType === type){
			return true;
		}
		if(getType === 'admin'){
			return true;
		}
		if(getType === 'editor'){
			return true;
		}
		if(getType === 'colorist'){
			return true;
		}
		if(getType === 'pm'){
			return true;
		}
		if(getType === 'mm'){
			return true;
		}
	}
	return false;
}

export const login = (user) => {
	return new Promise((resolve, reject) => {
		return api.post('backend/auth/user-authentication', user)
			.then(response => {
				if(response.status === 200){
					if (response.data.status === '200') {
						let dataUser = {
							id : response.data.id,
							first_name : response.data.first_name,
							last_name : response.data.last_name,
							email : response.data.email,
							type : response.data.user_type,
						};
						setUser(dataUser);
						setToken(response.data.auth_token);
						setUserType(response.data.user_type);
						setUserMeta(response.data.user_meta);
						
						resolve(dataUser);
					} 
				}
				reject(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export const logout = () => {
	return new Promise((resolve, reject) => {
		try {
			//session backend data
			localStorage.removeItem(Default.config_name.token_storage);
			localStorage.removeItem(Default.config_name.session_storage);
			localStorage.removeItem(Default.config_name.user_type_storage);
			localStorage.removeItem(Default.config_name.user_meta);
			resolve(true);
		} catch(error) {
			reject(error);
		}
	}); 
}

export const saveFcmToken = (currentToken) => {
	if(currentToken !== null){
		return api.post('user/update/fcm/token', {
			token: currentToken
		})
		.then(response => {
			
		})
		.catch(error => {
			
		})
	}
}