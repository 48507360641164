const initialState = {
	
};

export default (state = initialState, action) => {
  	switch (action.type) {
	    case 'UPDATE_PAGE_ORDER':
	      	return {
				link_left: action.link_left,
				link_top: action.link_top,
				year: action.year,
				page: action.page
	      	}	
      	case 'REVIEW_DETAIL_PAGE':
	      	return {
      			type: action.type,
				from: action.from,
				show: action.show,
				data: action.data
			  }    
		case 'CAPTURE_SCROLL':
			return {
				type: action.type,
				trigger: action.trigger,
				reload: action.show,
				dataPrev: action.data
			}
		case 'REFRESH_STATUS_ORDER':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_KANBAN':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_KANBAN_FROM_RIGHT_PANEL':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_RED_BOX_FROM_SMART_REVIEW':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_MEDIA_KANBAN_FROM_RIGHT_PANEL':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_MUSIC_KANBAN_FROM_RIGHT_PANEL':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_DELIVERED_KANBAN_FROM_RIGHT_PANEL':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
		case 'REFRESH_STATUS_NOTE_KANBAN_FROM_RIGHT_PANEL':
			return {
				type: action.type,
				trigger: action.trigger,
				data: action.data
			}
	    default:
      		return state
  	}
}