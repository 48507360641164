import React from 'react';
import {
    CBadge
} from '@coreui/react'
import { Spinner } from 'reactstrap';

import imgNoPicture from '../assets/images/no_picture.png';

const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Desc'];

export const common = {
    alert_notfication,
    showDate,
    showDateDefaultFormat,
    number_format,
    remindTime,
    generateTimeFilm,
    generateTimeToSecond,
    setDataDatePicker,
    sendDatePickerToServer,
    textSubstr,
    textLength,
    number_format_not_price,
    strToLower,
    permissionUser,
    diffDateGetDays,
    no_picture,
    replace_character,
    initialFirstLatterCompany,
    checkUrlNotification,
    checkHighLowOfDate,
    convertDataSearch,
    hmsToSeconds,
    loaderInfinityScroll,
    setDay,
    listMonthText,
    formatingTextBreadcumb,
    checkingTypeUser,
    checkingStatusDefault,
    listUserCategory,
    checkEnableFeature,
    showUsernameActivity,
    userCategory
};

function convertDataSearch(search = ''){
    if(search === ''){
        return '-';
    }
    return search.split(' ').join('+');
}

function checkHighLowOfDate(start, end){
    if(start !== ''){
        if(start.getTime() >= end.getTime()){
            return false;
        }
        return true;
    }
    return false;
}

function checkUrlNotification(url){
    var returnData = [];
    var tmpUrl = url.split('//');
    if(tmpUrl.length > 1){
        var splitHashTag = tmpUrl[1].split('#');

        var urlSegmen = tmpUrl[1].split('/');
        var newUrl = '';
        for (var i = 1; i < urlSegmen.length; i++) {
            newUrl +='/'+urlSegmen[i];
        }
        returnData['split_hashtag'] = splitHashTag;
        returnData['new_url'] = newUrl;
        returnData['url_segment'] = urlSegmen;
    }
    return returnData;
}

function initialFirstLatterCompany(companyName){
    var initial = '';
    var tmpData = companyName.split(' ');
    for (var i = 0; i < tmpData.length; i++) {
        initial += tmpData[i].substr(0, 1).toUpperCase();
    }
    return initial;
}

function replace_character(textData){
    // eslint-disable-next-line
    return textData.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '');
}
function no_picture(){
    return imgNoPicture;
}
function diffDateGetDays(serverTime, dataTime){
    var serverTimeConvert = new Date(serverTime.replace(/\s/, 'T'));
    var dataTimeConvert = new Date(dataTime.replace(/\s/, 'T'));

    var Difference_In_Time = serverTimeConvert.getTime() - dataTimeConvert.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
    return Math.ceil(Difference_In_Days);
}

function alert_notfication(nextProps) {
    nextProps = nextProps.data;
    if (nextProps !== undefined) {
        var notifId = (new Date()).getTime();
        var notifType = '';
        if (nextProps.status === '200') {
            notifType = 'success';
        } else if (nextProps.status === '400') {
            notifType = 'danger';
        } else {
            notifType = 'warning';
        }

        var notifData = nextProps.notification;
        var notif = [];
        var counter = 0;
        Object.keys(notifData).forEach(function (key) {
            notif.push({
                id: notifId + '' + counter,
                type: notifType,
                message: notifData[key],
                showIcon: false,
            });
            counter++;
        });
        return notif;
    }
    return false;
}

function number_format(price) {
    var tmpData = (price + '').split('.');
    if (tmpData.length > 1) {
        var lastChar = tmpData[1];
        if (lastChar.length === 1) {
            return tmpData[0] + '.' + lastChar + '0';
        } if (lastChar.length === 2) {
            return tmpData[0] + '.' + lastChar;
        } else {
            return tmpData[0] + '.' + lastChar.substr(0, 2);
        }
    } else {
        return tmpData[0] + '.00';
    }
}

function number_format_not_price(price) {
    return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

}

function formatingTextBreadcumb(text){
    let newText = '';
    newText = text.replace('_',' ');
    newText = newText.replace('-',' ');
    newText = newText.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    return newText;
}

function setNumberDay(day) {
    var tmp = parseInt(day) + 0;
    if (tmp <= 3) {
        if (tmp === 1) {
            return 'st';
        } else if (tmp === 2) {
            return 'nd';
        } else {
            return 'rd';
        }
    }

    return 'th';
}

function showDate(date) {
    var htmlDate = '';
    var tmpData = date.split('-');
    if (tmpData.length === 3) {
        var year = tmpData[0];
        var month = tmpData[1];
        var day = tmpData[2];
        
        return [day, setNumberDay(day), monthArray[(parseInt(month) - 1)], year];
    }
    return htmlDate;
}

function remindTime(dateNow, dateData, minusWords = false) {
    if(dateNow !== '' && dateData !== ''){
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var end = new Date(dateData.replace(/\s/, 'T'));
        var now = new Date(dateNow.replace(/\s/, 'T'));
        var result = 0;
        if(end > now){
            var tmpData = end;
            var tmpData2 = now;
            now = tmpData;
            end = tmpData2;
        }else{
            var tmpData21 = end;
            var tmpData22 = now;
            now = tmpData22;
            end = tmpData21;
        }
        var elapsed = now - end;

        var lastWording = '';

        if (elapsed < msPerMinute) {

            lastWording = 'sec';
            if(Math.round(elapsed / 1000) > 1){
                // lastWording = 'secs';
            }
            var seconds = Math.round(elapsed / 1000);
            if (seconds === 0) {
                if(minusWords){
                    return '1s';
                }else{
                    return '1 sec';
                }
            } else {
                return Math.round(elapsed / 1000) + (minusWords?'s':' '+lastWording+'s');
            }
        }

        else if (elapsed < msPerHour) {
            lastWording = 'min';
            result = Math.round(elapsed / msPerMinute);
            if(result > 1){
                // lastWording = 'mins';
            }
            return result + (minusWords?'min':' '+lastWording+(result>1?'s':''));
        }

        else if (elapsed < msPerDay) {
            lastWording = 'hour';
            result = Math.round(elapsed / msPerHour);
            if(result > 1){
                // lastWording = 'hours';
            }
            return result + (minusWords?'h':' '+lastWording+(result>1?'s':''));
        }

        else if (elapsed < msPerMonth) {
            lastWording = 'day';
            result = Math.round(elapsed / msPerDay);
            if(result > 1){
                // lastWording = 'days';
            }
            return result + (minusWords?'d':' '+lastWording+(result>1?'s':''));
        }

        else if (elapsed < msPerYear) {
            lastWording = 'month';
            result = Math.round(elapsed / msPerMonth);
            if(result > 1){
                // lastWording = 'months';
            }
            return result + (minusWords?'mon':' '+lastWording+(result>1?'s':''));
        }
        else {
            lastWording = 'year';
            result = Math.round(elapsed / msPerYear);
            if(result > 1){
                // lastWording = 'years';
            }
            return result + (minusWords?'y':' '+lastWording+(result>1?'s':''));
        }
    }
    return '';
}

function showDateDefaultFormat(date) {
    var htmlDate = '';
    var tmpData = date.substr(0, 10).split('-');
    if (tmpData.length === 3) {
        var year = tmpData[0];
        var month = tmpData[1];
        var day = tmpData[2];

        return month + '/' + day + '/' + year;
    }
    return htmlDate;
}

function generateTimeFilm(second) {
    var newSecond = Math.floor(parseFloat(second));

    var resultMinute = Math.floor(newSecond / 60);

    var frontSecond = '00';
    if (resultMinute > 0) {
        frontSecond = resultMinute;
        if ((resultMinute + '').length === 1) {
            frontSecond = '0' + resultMinute;
        }
    }

    var lastSecond = '00';
    var resSecond = newSecond - (resultMinute * 60);

    if (resSecond > 0) {
        lastSecond = resSecond;
        if ((resSecond + '').length === 1) {
            lastSecond = '0' + resSecond;
        }
    }

    return frontSecond + ':' + lastSecond;
}

function generateTimeToSecond(time) {
    var timeSplit = time.split(':');
    var minutes = parseFloat(timeSplit[0]) * 60;
    var seconds = parseFloat(timeSplit[1]) + 0;

    return (minutes + seconds);
}

function setDataDatePicker(date) {
    if (date === undefined || date === '' || date === null || date === 'null' || date === false) {
        return '';
    } else {
        return new Date(date.replace(/\s/, 'T'));
    }
}

function sendDatePickerToServer(date){
    if(date !== null && date !== '' && date !== 'null'){
        var myDate = '';
        var explodeDatePicker=String(date).split(" ");
        if(explodeDatePicker.length === 2){
            myDate = new Date(date.replace(/\s/, 'T'));
        } else {
            myDate = new Date(date);
        }
        var tmpData = myDate.toLocaleDateString('en-US').split('/');
        var day = tmpData[1];
        var month = tmpData[0];
        var year = tmpData[2];
        if(day.length===1){
            day = '0'+day;
        }
        if(month.length===1){
            month = '0'+month
        }
        return year+'-'+month+'-'+day+' 00:00:00';
    }else{
        return null;
    }
}

function listMonthText(month) {
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return months[(parseInt(month) - 1)];
}

function setDay(day, withHtml) {
    day = (parseInt(day) + 0);
    var getDay = day;
    var addCharDay = '';
    if (day > 9) {
        getDay = (day + '').substr(-1);
    } else {
        addCharDay = '0';
    }
    if (parseInt(getDay) > 0 && parseInt(getDay) < 4) {
        if (parseInt(getDay) === 1) {
            if (withHtml) {
                // return addCharDay + day + '<sup>st</sup>';
                return addCharDay + day + ' ';
            } else {
                // return addCharDay + day + 'st';
                return addCharDay + day + ' ';
            }
        } else if (parseInt(getDay) === 2) {
            if (withHtml) {
                // return addCharDay + day + '<sup>nd</sup>';
                return addCharDay + day + ' ';
            } else {
                // return addCharDay + day + 'nd';
                return addCharDay + day + ' ';
            }
        } else {
            if (withHtml) {
                // return addCharDay + day + '<sup>rd</sup>';
                return addCharDay + day + ' ';
            } else {
                // return addCharDay + day + 'rd';
                return addCharDay + day + ' ';
            }
        }
    } else {
        if (withHtml) {
            // return addCharDay + day + '<sup>th</sup>';
            return addCharDay + day + ' ';
        } else {
            // return addCharDay + day + 'th';
            return addCharDay + day + ' ';
        }
    }
}

function textSubstr(textData, dataLength) {
    if (textData !== null && textData !== undefined) {
        var newStr = textData.substr(0, dataLength);
        var addStr = '';
        if (textData.length > dataLength) {
            addStr = '..'
        }

        return newStr + addStr;
    } else {
        return ' ';
    }
}

function textLength(textData,  dataLength) {
    if (textData !== null && textData !== undefined) {
        if (textData.length > dataLength) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function strToLower(str) {
    return String(str).toLowerCase();
}

function permissionUser(userType, route){
    if(route === 'dashboard'){
        return true;
    }
    if(route === 'company'){
        return true;
    }
    if(route === 'profile'){
        return true;
    }
    if(route === 'order-list'){
        return true;
    }
    if(route === 'manage-user' || route === 'manage-price' || route === 'manage-coupon' || route === 'manage-faq' || route === 'report-issue' || route === 'contact-form' || route === 'manage-static-page' || route === 'manage-blog' || route === 'manage-currency'){
        return true;
    }
    if(route === 'notification'){
        return true;
    }
    return false;
}

function hmsToSeconds (stime)
{
  var tt = stime.split(':').reverse ();
  var seconds =  ((tt.length >= 3) ? (+tt[2]): 0)*60*60 + 
         ((tt.length >= 2) ? (+tt[1]): 0)*60 + 
         ((tt.length >= 1) ? (+tt[0]): 0);
  return seconds;
}

function loaderInfinityScroll(){
    return(
        <div align="center" className="mrg-top10">
            <Spinner type="grow" color="primary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="success" />
            <Spinner type="grow" color="danger" />
            <Spinner type="grow" color="warning" />
            <Spinner type="grow" color="info" />
            <Spinner type="grow" color="dark" />
        </div>
    )
}

function checkingTypeUser(type, size = ''){
    if(type === 'user'){
        return <CBadge shape="pill" color="primary">Client</CBadge>
    }else if(type === 'pm'){
        return <CBadge shape="pill" color="success">Project Manager</CBadge>
    }else if(type === 'mm'){
        return <CBadge shape="pill" color="info">Media Manager</CBadge>
    }else if(type === 'editor'){
        return <CBadge shape="pill" color="warning">Editor</CBadge>
    }
}

function checkingStatusDefault(status, size = '', draft = false){
    if(status+'' === '1'){
        return <CBadge shape="pill" color="success">Active</CBadge>
    }else {
        return <CBadge shape="pill" color={draft ? 'warning':'danger'}>{draft ? 'Draft':'Not Active'}</CBadge>
    }
}

function listUserCategory(type){
    return [
        {type: 'admin', text: 'Master Admnistrator'},
        {type: 'user', text: 'Client'},
        {type: 'pm', text: 'Project Manager'},
        {type: 'mm', text: 'Media Manager'},
        {type: 'editor', text: 'Editor'},
        // {type: 'colorist', text: 'Colorist'},
        // {type: 'archived', text: 'Archived'},
    ];
}

function checkEnableFeature(statusOrder, isFinish, isCancel, feature){
    var allowed = true;
    //actovity tab
    if(feature === 'write_new_comment' || feature === 'delete_comment' || feature === 'edit_comment' 
        || feature === 'delete_reply_comment' || feature === 'write_reply_comment'){
        // if(!statusOrder || isCancel || isFinish){
        //     allowed = false;
        // }
    }

    //overview
    if(feature === 'add_feedback' || feature === 'delete_feedback'){
        if(!statusOrder || isCancel){
            allowed = false;
        }
    }

    //media
    if(feature === 'add_media'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }
    }

    //note
    if(feature === 'add_note' || feature === 'confirm_note'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }
    }

    //preview
    if(feature === 'upload_preview' || feature === 'delete_preview'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }
    }

    //deliver
    if(feature === 'add_asset' || feature === 'maintenance_asset'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }
    }

    //deliver
    if(feature === 'new_comment_sm'){
        allowed = false;
    }

    //smart review
    if(feature === 'approve_sm' || feature === 'send_editor' || feature === 'approve_comment_sm'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }
    }

    //general style note
    if(feature === 'edit_note'){
        if(!statusOrder || isCancel || isFinish){
            allowed = false;
        }   
    }

    //invoice

    return allowed;
}

function showUsernameActivity(username, type, company){
    var getType = this.userCategory(type);
    if(type === 'user'){
        return username+' | '+company;
    }else{
        return username+' | '+getType;
    }
}

function userCategory(type, initial = false){
    var dataCategories = [];
    dataCategories['admin'] = ['Director', 'DRT'];
    dataCategories['user'] = ['Client', 'CLN'];
    dataCategories['pm'] = ['Project Manager', 'PM'];
    dataCategories['mm'] = ['Media Manager', 'MM'];
    dataCategories['editor'] = ['Editor', 'EDT'];
    dataCategories['colorist'] = ['Colorist', 'CLR'];

    if(initial){
        if(dataCategories[type] === undefined){
            return '...';
        }else{
            return dataCategories[type][1];
        }
    }else{
        if(dataCategories[type] === undefined){
            return '...';
        }else{
            return dataCategories[type][0];
        }
    }
}