const modalReducer = (state = {}, action) => {
    switch (action.type) {
        case 'MODAL_TOKEN_INVALID':
            return {
                modal_id: action.modal_id,
                show: action.show
            }
        case 'MODAL_REQUEST_ERROR':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data
            }
        case 'REFRESH_PP_FROM_MODAL_ASSIGN_EDITOR':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data,
            }
        case 'REFRESH_PP_FROM_MODAL_CHANGE_DUEDATE':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data,
            }
        case 'MODAL_NOTIFICATION':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data
            }
        case 'MODAL_MUSIC_LIBRARY':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data
            }
        case 'MODAL_OPEN_REFUND':
            return {
                modal_id: action.modal_id,
                show: action.show,
                data: action.data,
            }
        default:
            return state
    }
}

export default modalReducer;